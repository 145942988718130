<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'videos' }">
          Video</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="display-1 font-weight-bold my-5 text-capitalize">
        Update Video
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mt-6 pa-0">
        <v-form @submit.prevent="submit" ref="form">
          <v-divider class="pb-5"></v-divider>

          <label class="text-field-label">Name</label>
          <v-text-field
            flat
            solo
            required
            counter
            class="mt-2"
            :rules="rules.name"
            maxlength="255"
            v-model="form.name"
            :error-messages="form.$getError('name')"
          ></v-text-field>
          <div>
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-files-added="startUpload"
              @vdropzone-upload-progress="dropzoneUploadProgress"
              @vdropzone-success="uploadSuccess"
            ></vue-dropzone>
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              color="deep-purple accent-4"
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          <v-divider class="my-5"></v-divider>

          <div class="mt-4">
            <v-btn
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="70%"
              @click="submit"
              :loading="form.$busy"
              ><v-icon left dark> {{ icons.update }} </v-icon>Update</v-btn
            >
          </div>
          <div class="mt-4">
            <v-btn
              @click="showDeleteModal = true"
              height="40px"
              width="70%"
              color="error"
              ><v-icon left dark> {{ icons.remove }} </v-icon>Delete
              Videos</v-btn
            >
          </div>
        </v-form>
      </div>
    </div>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Video"
      message="Are you sure you want to delete video?"
      @cancel="showDeleteModal = false"
      @confirm="removeVideo"
    ></ConfirmModal>
  </div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { each } from 'lodash'
import { mapActions, mapState, mapMutations } from 'vuex'
import { mdiChevronLeft, mdiTrashCan, mdiUpdate } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Cookies from 'js-cookie'
import Form from '@/utils/form'
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: 'VideoDetails',

  components: {
    vueDropzone: vue2Dropzone,
    AppBarNavIcon,
    ConfirmModal,
  },

  data: function () {
    return {
      dropzoneOptions: {
        methods: 'PUT',
        url: process.env.VUE_APP_API_URL + '/admin/videos/upload',
        headers: {
          Authorization:
            'Bearer ' + Cookies.getJSON('AUTH_TOKEN')['access_token'],
        },
        video_id: this.$route.params.id,
        parallelUploads: 1,
        maxFilesize: 500000,
        maxFiles: 1,
        addRemoveLinks: true,
        autoProcessQueue: true,
        timeout: 600000,
        init: function () {
          this.on('sending', function (file, xhr, formData) {
            formData.append('id', this.options.video_id)
          })
        },
      },
      showDeleteModal: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        remove: mdiTrashCan,
        update: mdiUpdate,
      },
      rules: {
        name: [(v) => !!v || 'Name is required'],
      },
      form: new Form({
        name: '',
      }),
      file: [],
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      video_id: '',
      loading: false,
    }
  },

  computed: {
    ...mapState({
      videoDetails: (state) => state.video.videoDetails,
    }),
  },
  methods: {
    ...mapActions({
      updateVideo: 'video/updateVideo',
      getVideoDetails: 'video/getVideoDetails',
      deleteVideo: 'video/deleteVideo',
    }),

    ...mapMutations({
      clearVideoDetails: 'video/clearVideoDetails',
    }),

    dropzoneUploadProgress(e) {
      this.progress = Math.round(e.upload.progress)
    },

    async getVideo() {
      this.loading = true
      await this.getVideoDetails(this.$route.params.id)
      this.form = new Form(this.videoDetails)
      var file = {
        size: this.videoDetails.size,
        name: this.videoDetails.file_name,
        type: this.videoDetails.mime_type,
      }

      this.$refs.myVueDropzone.manuallyAddFile(file, this.videoDetails.url)
      this.loading = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      const formData = new FormData()

      each(this.form.$data(), (value, key) => {
        formData.append(key, value)
      })
      formData.append('file', this.file)
      formData.append('_method', 'PUT')

      const data = {
        data: formData,
        id: this.$route.params.id,
      }

      this.updateVideo(data)
        .then(() => {
          this.showSnackbar('Videos successfully updated!', 'success')
          setTimeout(() => {
            this.$router.push({
              name: 'videos',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }

          this.form.$busy = false
        })
    },
    startUpload() {
      this.loading = true
    },

    uploadSuccess(file, response) {
      this.loading = false
      this.file = file
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    removeVideo() {
      this.loading = true

      this.deleteVideo(this.$route.params.id)
        .then(() => {
          this.$router.push({ name: 'videos' })
        })
        .catch((error) =>
          this.showSnackbar(error.response.data.message, 'error')
        )
        .finally(() => (this.loading = false))
    },
  },
  created() {
    this.getVideo()
  },

  destroyed() {
    this.clearVideoDetails()
  },

  watch: {
    $route() {
      this.getVideo()
    },
  },
}
</script>
